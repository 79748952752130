import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

// routes config
import RouteData from './routes';
import Spinner from './assets/loading/loading.gif';
import { useSelector, useDispatch } from 'react-redux';
import * as AuthAction from './store/actions/AuthAction';

const loadingLazy = (
  <div className='picture '>
    <div className='lazy-load'>
      <img src={Spinner} alt='Loading' style={{ width: '30px' }} />
    </div>
  </div>
);
let UID = false;

const TheContent = () => {
  const [isLoading, setisLoading] = React.useState(true);
  const [USERS, setUSERS] = React.useState(null);

  const dispatch = useDispatch();
  UID = useSelector((state) => state.auth.userId);

  React.useEffect(() => {
    setisLoading(true);
    async function load() {
      console.log('UID', UID);
      if (UID) setUSERS(UID);
      else await dispatch(AuthAction.CheckAuthenticate());

      setisLoading(false);
    }
    load();
  }, [UID, dispatch]);

  return (
    <Suspense fallback={loadingLazy}>
      {/* <loadingLazy /> */}
      {isLoading ? (
        ''
      ) : (
        <>
          <Routes>
            {RouteData.map((route, idx) => {
              let Compo;
              if (route.private) {
                if (!USERS) Compo = <Navigate to='/login' />;
                else Compo = <route.component />;
              } else if (route.reprivate) {
                if (USERS) Compo = <Navigate to='/profile' />;
                else Compo = <route.component />;
              } else Compo = <route.component />;

              return (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={Compo}
                />
              );
            })}
          </Routes>
        </>
      )}
    </Suspense>
  );
};

export default React.memo(TheContent);

var months = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember',
];

var weekdays = [
  'Minggu',
  'Senin',
  'Selasa',
  'Rabu',
  'Kamis',
  `Jum'at`,
  'Sabtu',
];

export const convertIndonesia = (date, all = true) => {
  // from 2020-10-09 9:37:55
  // to 9 Okt 2020, 9:37 WIB.
  // console.log('convertIndonesia', date);
  date = date.replace(/-/g, '/');

  // console.log('date1', d);
  var d = new Date(date);
  // console.log('date2', d.getDay());
  var hour = d.getHours();
  let ret = `${all ? weekdays[d.getDay()] + ', ' + d.getDate() : ''} ${
    months[d.getMonth()]
  } ${d.getFullYear()}`;
  // ${
  //   hour % 24
  // }:${
  //   d.getMinutes() < 10 ? '0' + d.getMinutes().toString() : d.getMinutes()
  // } WIB.`;
  // console.log('Ret convertIndonesia', ret);
  return ret;
};

export const UTC_Date = (date) => {
  // console.log('date', date);
  var m = new Date(date);
  // console.log('m', m);
  var dateString =
    m.getFullYear() +
    '-' +
    ('0' + (m.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + m.getDate()).slice(-2) +
    ' ' +
    ('0' + m.getHours()).slice(-2) +
    ':' +
    ('0' + m.getMinutes()).slice(-2) +
    ':' +
    ('0' + m.getSeconds()).slice(-2);

  // console.log('dateString', dateString);
  return dateString;
};

export const TimeToInteger = (datetime) => {
  let time = datetime.split(':');

  let hour = time[0] * 3600;
  let menit = time[1] * 60;
  let detik = parseInt(time[2]);

  return hour + menit + detik;
};

export const IntegerToTime = (datetime) => {
  let jam = parseInt(datetime / 3600);
  let jam_sisa = parseInt(datetime % 3600);

  let menit = parseInt(jam_sisa / 60);
  let menit_sisa = parseInt(jam_sisa % 60);

  let detik = parseInt(menit_sisa);

  return jam + ':' + menit + ':' + detik;
};

export const TimeWithUTC = (datetime) => {
  let time = datetime.split(':');
  let jam = parseInt(time[0]) > 0 ? time[0] + ' jam ' : '';
  let menit = parseInt(time[1]) > 0 ? time[1] + ' menit ' : '';
  let detik = parseInt(time[2]) > 0 ? time[2] + ' detik' : '';
  return jam + menit + detik;
};

export const LateCalculation = (in_work, pegawai_absen) => {
  let jamAbsen = TimeToInteger(in_work);
  let pegawaiAbsen = TimeToInteger(pegawai_absen);

  return TimeWithUTC(IntegerToTime(pegawaiAbsen - jamAbsen));
};

import { GET_TIME, CHECK_IN } from '../actions/StatisticAction';
import { CheckCache } from '../ApiGate';

const initialState = {
  time: null,
  absen: CheckCache('ABSEN'),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TIME:
      return {
        ...state,
        time: action.data,
      };

    case CHECK_IN:
      return {
        ...state,
        absen: action.data,
      };

    default:
      return state;
  }
};

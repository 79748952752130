import ApiGate from '../ApiGate';

// const instance = axios.create({
//   baseURL: process.env.REACT_APP_URI_TEST,
// });

export async function GetTimeApi(latitude, longtitude, shift_id, absen) {
  let functionName = 'GetTime';
  let url = `/user/statistic`;
  let params = {
    latitude: latitude.toString(),
    longtitude: longtitude.toString(),
    shift_id,
    type: absen.toLowerCase(),
  };
  // console.log(params);
  return await ApiGate(url, 'POST', functionName, true, params);
}

export async function UploadImageApi(file) {
  let functionName = 'UploadImage';
  let url = `/test-upload`;
  let fd = new FormData();
  fd.append('photo', file, file.name);
  let photo = fd;
  // console.log('photo', photo);
  return await ApiGate(
    url,
    'POST',
    functionName,
    true,
    photo
    // 'multipart/form-data'
  );
}

export async function CheckInApi(params, backup) {
  let functionName = 'CheckIn';
  let url = `/user/attendance`;

  let fd = new FormData();
  // fd.append('photo', params.photo, params.photo.name);
  let reParams = {
    ...params,
    backup: JSON.stringify(backup),
    // photo: fd,
  };
  // console.log('reParams', reParams);
  return await ApiGate(
    url,
    'POST',
    functionName,
    true,
    reParams
    // 'multipart/form-data'
  );
}

export async function CheckOutApi(id, backup) {
  let functionName = 'CheckOut';
  let url = `/user/attendance/out/${id}`;
  let params = {
    time: backup.checkOut.date_time,
    backup: JSON.stringify(backup),
  };
  // console.log('params', params);
  return await ApiGate(
    url,
    'POST',
    functionName,
    true,
    params
    // 'multipart/form-data'
  );
}

import {
  GetTimeApi,
  CheckInApi,
  UploadImageApi,
  CheckOutApi,
} from '../Context/StatisticContext';

import {
  AbsenModel,
  CheckInModel,
  CheckOutModel,
} from '../Model/StatisticModel';
import { saveDataToStorage } from '../ApiGate';
import { CHOOSE_SHIFT } from './DataAction';
// import { UTC_Date } from '../../utility/dateParse';

export const GET_TIME = 'GET_TIME';
export const CHECK_IN = 'CHECK_IN';

export const GetTime = (lat, lng, shift, absen) => {
  return async (dispatch, getState) => {
    let shiftChoose = getState().content.shiftChoose;
    let res = await GetTimeApi(lat, lng, shift, absen);
    // console.log('GetTimeApi', res);

    if (res.status) {
      let data = {
        ...shiftChoose,
        absen: res.data.date_time,
      };
      saveDataToStorage(data, new Date(), 'CHOOSE_SHIFT');
      dispatch({ type: CHOOSE_SHIFT, data });

      dispatch({ type: GET_TIME, data: res.data });
      return res;
    } else {
      dispatch({ type: GET_TIME, data: null });
      return {
        status: false,
        error: res.errors ? res.errors : res,
      };
    }
  };
};

export const CheckIn = (data, shiftChoose, store) => {
  return async (dispatch) => {
    // let shiftChoose = getState().content.shiftChoose;
    //console.log('data CheckIn', data);
    //console.log('store', store);
    //console.log('shiftChoose', shiftChoose);
    let backup = {
      checkIn: new CheckInModel(
        true,
        data.time ? data.time : data.date_time,
        // shiftChoose.absen,
        store.data ? store.data.id : { id: data.store_id },
        shiftChoose.type,
        shiftChoose.dataShift,
        data
        // res.data
      ),
      checkOut: new CheckOutModel(false, shiftChoose.dataShift.out_start),
    };
    //console.log('backup', backup);
    // console.log('CheckIn', data);
    // return;
    let res = await CheckInApi(data, backup);
    // console.log('shiftChoose', shiftChoose);
    // console.log('CheckInApi', res);

    if (res.status) {
      // let dataShift =
      let dataCache = {
        data: new AbsenModel(
          res.data.id,
          new Date(),
          backup.checkIn,
          backup.checkOut
        ),
      };
      // console.log('dataCache', dataCache);
      // await dispatch(GetHistory());
      let dataChoos = {
        ...shiftChoose,
        type: 'out',
      };
      saveDataToStorage(dataChoos, new Date(), 'CHOOSE_SHIFT');
      dispatch({ type: CHOOSE_SHIFT, dataChoos });
      saveDataToStorage(dataCache, new Date(), 'ABSEN');
      dispatch({ type: CHECK_IN, data: dataCache.data });

      return res;
    } else {
      dispatch({ type: CHECK_IN, data: null });
      return { status: false, error: res.errors ? res.errors : res };
    }
  };
};
export const CheckOut = (data) => {
  return async (dispatch, getState) => {
    // console.log('CheckOut', data);
    let availableAbsen = getState().statistic.absen;
    // console.log('availableAbsen', availableAbsen);
    if (!availableAbsen) return;
    // console.log('CheckOut', data);

    let dataCache = {
      data: {
        ...availableAbsen,
        checkOut: {
          request: { longtitude: data.longtitude, latitude: data.latitude },
          status: true,
          date_time: data.time,
        },
      },
    };
    let res = await CheckOutApi(availableAbsen.id, dataCache.data);
    // console.log('CheckOutApi res', res);
    // console.log('dataCache', dataCache);

    if (res.status) {
      // console.log('dataCache', dataCache);
      saveDataToStorage(dataCache, new Date(), 'ABSEN');
      dispatch({ type: CHECK_IN, data: dataCache.data });

      // await dispatch(GetHistory());

      return dataCache;
    } else {
      // dispatch({ type: CHECK_IN, data: null });
      return { status: false, error: res.errors ? res.errors : res };
    }
  };
};

export const UploadImage = (data) => {
  return async (dispatch) => {
    //console.log('CheckIn', data);
    let res = await UploadImageApi(data);

    if (res.status) {
      dispatch({ type: CHECK_IN, data: res.data });
      return res;
    } else {
      dispatch({ type: CHECK_IN, data: null });
      return { status: false, error: res.errors };
    }
  };
};

export const ConvertImage = (image, filename = '') => {
  return async (dispatch) => {
    var arr = image.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let blobing = new File([u8arr], filename, { type: mime });
    let formData = new FormData();
    const readerData = new FileReader();
    // const objUrl = URL.createObjectURL(blobing);
    // var request = new XMLHttpRequest();

    readerData.onload = function (event) {
      // The file's text will be printed here
      // console.log('event reader', event.target.result);
    };
    readerData.readAsDataURL(blobing);
    // readerData.readAsText(blobing);

    // let remake = {
    //   name: blobing.name,
    //   size: blobing.size,
    //   type: blobing.type,
    //   lastModifiedDate: blobing.lastModifiedDate,
    //   lastModified: blobing.lastModified,
    // };
    // console.log('remake', remake);

    // for (var [key, value] of Object.entries(remake)) {
    // console.log('loop', key, value);
    // formData.set(key, value);
    // }

    // formData.set('image', blobing);
    // formData.set('name', filename);
    formData.append('image', blobing, filename);
    // formData.set('name', filename);

    // readerData.onload = (readerEvent) => {
    ////console.log('readerEvent', readerEvent);
    //   formData.append('file', readerEvent.target.result);
    // };

    return blobing;
  };
};

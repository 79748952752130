import {
  GET_SHIFT,
  CHOOSE_SHIFT,
  GET_STORE,
  GET_HISTORY,
  IS_LOADING,
  GET_JOB,
} from '../actions/DataAction';
import { CheckCache } from '../ApiGate';

const initialState = {
  store: CheckCache('STORE'),
  shift: CheckCache('SHIFT'),
  shiftChoose: CheckCache('CHOOSE_SHIFT'),
  jobList: null,
  history: CheckCache('HISTORY'),
  historyLoading: true,

  RANGE: 0.012,
  SIZE_RADIUS: 1300,
  // RANGE: 0.004,
  // SIZE_RADIUS: 500,
  // RANGE: 0.002,
  // SIZE_RADIUS: 200,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SHIFT:
      return {
        ...state,
        shift: action.data,
      };

    case GET_STORE:
      return {
        ...state,
        store: action.data,
      };

    case CHOOSE_SHIFT:
      return {
        ...state,
        shiftChoose: action.data,
      };
    case GET_HISTORY:
      return {
        ...state,
        history: action.data,
      };
    case GET_JOB:
      return {
        ...state,
        jobList: action.data,
      };
    case IS_LOADING:
      return {
        ...state,
        historyLoading: action.data,
      };

    default:
      return state;
  }
};

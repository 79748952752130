import React, { lazy } from 'react';
// import loadable from '@loadable/component';
// const GuestList = lazy(() => import('./views/Mine/Guest/GuestList'));
const Home = lazy(() => import('./Page/Home'));
const Error = lazy(() => import('./Page/Error'));
// const Absen = lazy(() => import('./Page/Absen/Absen'));
const Absen2 = lazy(() => import('./Page/Absen/Absen2'));
const Login = lazy(() => import('./Page/Login/Login'));
const Logout = lazy(() => import('./Page/Login/Logout'));
const Forgot = lazy(() => import('./Page/Login/Forgot'));
const Signup = lazy(() => import('./Page/Login/Signup'));
const Profile = lazy(() => import('./Page/Profile/Profile'));
const History = lazy(() => import('./Page/Profile/History'));
const HistoryDetail = lazy(() => import('./Page/Profile/HistoryDetail'));
const Camera = lazy(() => import('./Feature/CameraPro'));
const MapLocation = lazy(() => import('./Feature/MapLocation'));
const Geolocation = lazy(() => import('./Feature/Geolocation'));

export default [
  {
    path: '/',
    component: Home,
    name: 'home',
    exact: true,
    header: true,
    footer: true,
    active: true,
    private: false,
    reprivate: false,
  },
  {
    path: '/login',
    component: Login,
    name: 'login',
    exact: true,
    header: true,
    footer: true,
    active: true,
    private: false,
    reprivate: true,
  },
  {
    path: '/logout',
    component: Logout,
    name: 'logout',
    exact: true,
    header: true,
    footer: true,
    active: true,
    private: true,
    reprivate: false,
  },
  {
    path: '/forgot',
    component: Forgot,
    name: 'forgot',
    exact: true,
    header: true,
    footer: true,
    active: true,
    private: false,
    reprivate: true,
  },
  {
    path: '/signup',
    component: Signup,
    name: 'signup',
    exact: true,
    header: true,
    footer: true,
    active: true,
    private: false,
    reprivate: true,
  },
  {
    path: '/profile',
    component: Profile,
    name: 'profile',
    exact: true,
    header: true,
    footer: true,
    active: true,
    private: true,
    reprivate: true,
  },
  {
    path: '/history',
    component: History,
    name: 'history',
    exact: true,
    header: true,
    footer: true,
    active: true,
    private: true,
    reprivate: true,
  },
  {
    path: '/detail',
    component: HistoryDetail,
    name: 'detail',
    exact: false,
    header: true,
    footer: true,
    active: true,
    private: true,
    reprivate: true,
  },
  {
    path: '/camera',
    component: Camera,
    name: 'camera',
    exact: true,
    header: true,
    footer: true,
    active: true,
    private: true,
    reprivate: true,
  },
  // {
  //   path: '/absen2',
  //   component: Absen,
  //   name: 'absen',
  //   exact: true,
  //   header: true,
  //   footer: true,
  //   active: true,
  //   private: true,
  //   reprivate: true,
  // },
  {
    path: '/absen',
    component: Absen2,
    name: 'absen2',
    exact: false,
    header: true,
    footer: true,
    active: true,
    private: true,
    reprivate: true,
  },
  {
    path: '/maps',
    component: MapLocation,
    name: 'maps',
    exact: true,
    header: true,
    footer: true,
    active: true,
    private: true,
    reprivate: true,
  },
  {
    path: '/location',
    component: Geolocation,
    name: 'location',
    exact: true,
    header: true,
    footer: true,
    active: true,
    private: true,
    reprivate: true,
  },
  {
    path: '*',
    component: Error,
    name: 'Error',
    exact: true,
    header: true,
    footer: true,
    active: true,
    private: false,
    reprivate: false,
  },
];

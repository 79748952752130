export class StoreModel {
  constructor(id, title, lat, long, address, pic, phone, email) {
    this.id = id;
    this.title = title;
    this.loc = [lat, long];
    this.address = address;
    this.pic = pic;
    this.phone = phone;
    this.email = email;
  }
}

export class ShiftModel {
  constructor(id, shift, time) {
    this.id = id;
    this.shift = shift;
    // Masuk
    this.in_start = time.start_in_time;
    this.in_work = time.in_time;
    this.in_off = time.last_in_time;
    // Pulang
    this.out_start = time.start_out_time;
    this.out_work = time.out_time;
    this.out_off = time.last_out_time;
  }
}

export class HistoryModel {
  constructor(
    id,
    date,
    time_in,
    time_out,
    status_in,
    status_out,
    shift,
    offset_time_in,
    offset_time_out,
    store,
    dataShift,
    user,
    backup
  ) {
    this.id = id;
    this.date = date;
    this.time_in = time_in;
    this.time_out = time_out;
    this.status_in = status_in;
    this.status_out = status_out;
    this.shift = shift;
    this.offset_time_in = offset_time_in;
    this.offset_time_out = offset_time_out;
    this.store = store;
    this.dataShift = dataShift;
    this.user = user;
    this.backup = backup;
  }
}

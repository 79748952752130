import axios from 'axios';
import { AsyncStorage } from 'AsyncStorage';
import { CheckToken } from './Context/AuthContext';

let baseUrl = process.env.REACT_APP_URI_TEST + '/api/v1';

// const https = require('https');

// const agent = new https.Agent({
//   rejectUnauthorized: false,
// });

// const instance = axios.create({
//   baseURL: baseUrl,
// headers: {
//   'Content-Type': 'application/json',
//   Authorization: '',
// },
// withCredentials: true,
// timeout: 15000,
// });

// const customAxios = (contentType) => {
//   // axios instance for making requests
//   const axiosInstance = axios.create({
//     // your other properties for axios instance
//   baseURL: baseUrl,
//     headers: {
//       'Content-Type': contentType,
//     },
//   });

//   // your response interceptor
//   axiosInstance.interceptors.response.use(// handle response);

//   return axiosInstance;
// };

export default async function ApiGate(
  uri,
  method,
  access = '',
  token = true,
  params = {},
  contentApp = 'application/json;charset=UTF-8'
) {
  // const [setCookie] = useCookies(['cacheContext']);
  // console.log('REACT_APP_URI_TEST', baseUrl);
  let response;
  // let uri = url + '?nocache=' + new Date().getTime();
  let url = baseUrl + uri;
  //console.log('url', url);

  let headers = {
    headers: {
      'Content-Type': contentApp,
      'Access-Control-Allow-Origin': '*',
    },
  };

  if (token) {
    let checkToken = await CheckCache('UID', true);
    // console.log('checkToken', checkToken);
    if (!checkToken) {
      alert('Sesi telah berakhir, silahkan login kembali');
      window.location.href = '/logout';
    }
    //console.log('contentApp', contentApp);

    headers = {
      headers: {
        ...headers.headers,
        token: checkToken,
      },
    };
    // instance.interceptors.request.use(function (config) {
    //   const tokenId = checkToken;
    //   config.headers.token = tokenId ? tokenId : '';
    //   config.headers.common['content-type'] = contentApp;
    //   return config;
    // });
    // console.log('axios', axios);
    // console.log('instance.interceptors', instance.interceptors);
  }
  //console.log('headers', headers);
  if (method === 'GET') {
    response = await axios
      .get(url, headers)
      .then((res) => ({ ...res.data, cache: false }))
      .catch((err) => erroreHandle(err));
  }

  if (method === 'POST') {
    //console.log('params', method, params);
    // console.log('response', response);
    response = await axios
      .post(url, params, headers)
      .then((res) => {
        //console.log('POST res', res);
        return res.data;
      })
      .catch((err) => erroreHandle(err));
  }

  if (method === 'PUT') {
    response = await axios
      .put(url, headers)
      .then((res) => res.data)
      .catch((err) => erroreHandle(err));
  }

  if (method === 'DEL') {
    response = await axios
      .del(url, headers)
      .then((res) => res.data)
      .catch((err) => erroreHandle(err));
  }

  async function erroreHandle(err) {
    if (err.response)
      if (err.response.data)
        if (err.response.data.errors) return err.response.data;

    if (err.message) return { status: false, errors: err.message };

    return err;
  }
  return response;
}

export const saveDataToStorage = (dataPass, expire, funcName) => {
  // const expirationDate =
  //   expire && expire.getTime() !== new Date().getTime()
  //     ? expire
  //     : new Date().getTime() + 10000000;

  let data = JSON.stringify({
    ...dataPass,
    // expiryDate: expirationDate,
    expiryDate: expire.getTime(),
  });
  localStorage.setItem(funcName, data);
};

export function CheckCache(params = '', token = false, data = '') {
  let localData = localStorage.getItem(params);

  if (!localData) {
    return null;
  } else {
    localData = JSON.parse(localData);
  }

  const now = new Date().getDate();
  const expi = new Date(localData.expiryDate).getDate();
  // let kurang = expi.getTime() - now.getTime();
  // let diferDay = expi;
  // console.log('kurang', kurang);
  // console.log(params, ' == ', 'expi', expi, 'now', now, '===', localData);
  // console.log('now date', now.getDate());
  try {
    if (now !== expi) {
      localStorage.removeItem(params);
      return null;
    }
  } catch (exception) {
    return null;
  }
  // console.log('ece', params, localData, token, data, localData[data]);

  if (token) {
    if (data === '') return localData.token;
    else return localData[data];
  }
  if (localData.data) return localData.data;
  return localData;
}

import './App.css';
// import WebCam from './WebCam';

import { BrowserRouter as Router } from 'react-router-dom';
import Content from './content';

import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import ReduxThunk from 'redux-thunk';

import AuthReducer from './store/reducer/AuthReducer';
import DataReducer from './store/reducer/DataReducer';
import StatisticReducer from './store/reducer/StatisticReducer';

const rootReducer = combineReducers({
  auth: AuthReducer,
  content: DataReducer,
  statistic: StatisticReducer,
});

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Content />
      </Router>
    </Provider>
  );
}

export default App;

export class AbsenModel {
  constructor(id, date, checkIn, checkOut) {
    // this.absenDate = absenDate;
    this.id = id;
    this.date = date;
    this.checkIn = checkIn;
    this.checkOut = checkOut;
  }
}

export class CheckInModel {
  constructor(
    status,
    date_time,
    store,
    type,
    dataShift,
    request
    // response
  ) {
    // this.absenDate = absenDate;
    this.status = status;
    this.date_time = date_time;
    this.store = store;
    this.type = type;
    this.dataShift = dataShift;
    this.request = request;
    // this.response = response;
  }
}

export class CheckOutModel {
  constructor(status, startIn, request, response) {
    // this.absenDate = absenDate;
    this.status = status;
    this.startIn = startIn;
  }
}

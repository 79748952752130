import ApiGate from '../ApiGate';

export async function getShiftApi() {
  let functionName = 'getShift';
  let url = `/shift`;
  return await ApiGate(url, 'GET', functionName);
}

export async function getStoreApi() {
  let functionName = 'getStore';
  let url = `/store`;
  return await ApiGate(url, 'GET', functionName);
}

export async function getJobApi() {
  let functionName = 'getJob';
  let url = `/position`;
  return await ApiGate(url, 'GET', functionName, false);
}

export async function getHistoryApi(params) {
  let functionName = 'getHistory';
  // &uid=${params.UID}
  let url = `/user/history?month=${params.month}`;
  return await ApiGate(url, 'GET', functionName);
}

export async function getHistoryDetailApi(id) {
  let functionName = 'getHistoryDetail';
  // &uid=${params.UID}
  let url = `/user/history/${id}`;
  return await ApiGate(url, 'GET', functionName);
}

export class AuthModel {
  constructor(userId, username, token, phone, active, Position) {
    this.userId = userId;
    this.username = username;
    this.token = token;
    this.phone = phone;
    this.active = active;
    this.position = Position;
  }
}

import {
  getStoreApi,
  getShiftApi,
  getHistoryApi,
  getJobApi,
  getHistoryDetailApi,
} from '../Context/DataContent';
import { saveDataToStorage } from '../ApiGate';
import { ShiftModel, StoreModel, HistoryModel } from '../Model/DataModel';
import { CHECK_IN } from './StatisticAction';
import { LateCalculation } from '../../utility/dateParse';

export const GET_HISTORY = 'GET_HISTORY';
export const GET_STORE = 'GET_STORE';
export const GET_SHIFT = 'GET_SHIFT';
export const GET_JOB = 'GET_JOB';
export const CHOOSE_SHIFT = 'CHOOSE_SHIFT';
export const IS_LOADING = 'IS_LOADING';

const Failed = (params, message = null) => {
  return async (dispatch) => {
    dispatch({ type: params, data: null });
    dispatch({ type: IS_LOADING, data: false });
    //console.log('message', message);
    if (message) {
      if (message.errors) alert(message.errors[0]);
      return message;
    }
    return false;
  };
};

export const GetHistory = (month) => {
  return async (dispatch, getState) => {
    dispatch({ type: IS_LOADING, data: true });
    //console.log('month', month);
    let year = new Date().getFullYear().toString();
    if (!month) month = (new Date().getMonth() + 1).toString();
    month = month.length === 1 ? '0' + month : month.toString();
    let params = { month: year + '-' + month };

    let res = await getHistoryApi(params);

    if (res.status) {
      if (res.data.length === 0) {
        dispatch({ type: GET_HISTORY, data: [] });
        return [];
      }
      let mapData = res.data.map((item, i) => {
        let newData = new HistoryModel(
          item.id,
          item.date,
          item.time_in,
          item.time_out,
          item.status_in,
          item.status_out,
          item.shift.shift,
          item.offset_time_in,
          item.offset_time_out,
          item.store,
          item.shift,
          item.user,
          item.backup
        );

        let date2 = new Date(item.date).getDate();
        let today = new Date().getDate();
        if (date2 === today) {
          let backup = JSON.parse(item.backup);

          let time = backup.checkIn.request.time;
          let todayData = {
            data: {
              ...backup,
              id: item.id,
              date: time,
            },
            expiryDate: time,
          };

          saveDataToStorage(todayData, new Date(), 'ABSEN');
          dispatch({ type: CHECK_IN, data: todayData.data });
        }
        return newData;
      });

      dispatch({ type: GET_HISTORY, data: mapData });
      saveDataToStorage({ data: mapData }, new Date(), 'HISTORY');
      dispatch({ type: IS_LOADING, data: false });

      return mapData;
    } else {
      return dispatch(Failed(GET_HISTORY, res));
    }
  };
};

export const GetHistoryDetail = (id) => {
  return async (dispatch) => {
    dispatch({ type: IS_LOADING, data: true });
    let res = await getHistoryDetailApi(id);
    // console.log('getHistoryDetailApi', res);
    // return;

    if (res.status) {
      let resdata = res.data[0];

      if (res.data.length === 0) return dispatch(Failed(GET_HISTORY));
      let mapData = new HistoryModel(
        resdata.id,
        resdata.date,
        resdata.time_in,
        resdata.time_out,
        resdata.status_in,
        resdata.status_out,
        resdata.Shift.shift,
        resdata.offset_time_in,
        resdata.offset_time_out,
        resdata.Store,
        resdata.Shift,
        resdata.User,
        resdata.backup
      );

      dispatch({ type: IS_LOADING, data: false });

      return mapData;
    } else {
      return dispatch(Failed(GET_HISTORY, res));
    }
  };
};

export const getJob = () => {
  return async (dispatch) => {
    dispatch({ type: IS_LOADING, data: true });

    let res = await getJobApi();

    if (res.status) {
      if (res.data.length === 0) return dispatch(Failed(GET_JOB));
      dispatch({ type: GET_JOB, data: res.data });
      dispatch({ type: IS_LOADING, data: false });
      return res.data;
    } else {
      return dispatch(Failed(GET_JOB, res));
    }
  };
};

export const GetStore = () => {
  return async (dispatch) => {
    let res = await getStoreApi();
    // console.log('getStoreApi', res.data);

    if (res.status) {
      if (res.data.length === 0) return;
      let mapData = res.data.map((item, i) => {
        return new StoreModel(
          item.id,
          item.name,
          item.latitude,
          item.longtitude,
          item.address,
          item.pic_name,
          item.phone,
          item.email
        );
      });
      let data = { data: mapData };

      dispatch({ type: GET_STORE, data: data.data });
      saveDataToStorage(data, new Date(), 'STORE');
      //console.log('mapData Store', data.data);

      return data.data;
    } else {
      dispatch({ type: GET_STORE, data: null });
      // return {status:false, error: res.errors};
      return dispatch(Failed(GET_STORE, res));
    }
  };
};

export const GetShift = () => {
  return async (dispatch) => {
    let res = await getShiftApi();
    // console.log('getShiftApi', res.data);
    if (res.status) {
      if (res.data.length === 0) return;
      let mapData = res.data.map((item) => {
        return new ShiftModel(item.id, item.shift, item);
      });

      let data = { data: mapData };
      dispatch({ type: GET_SHIFT, data: data.data });
      saveDataToStorage(data, new Date(), 'SHIFT');
      //console.log('data.data shift', data.data);

      return data.data;
    } else {
      dispatch({ type: GET_SHIFT, data: null });
      return dispatch(Failed(GET_SHIFT, res));
    }
  };
};

export const ChooseShift = (shift) => {
  return async (dispatch, getState) => {
    // console.log('ChooseShift', shift);
    let cekState = getState().content.shift;
    //console.log('cekState', shift, cekState);
    let dataShift = cekState.find((item) => item.id === shift.shift);
    let data = {
      ...shift,
      dataShift,
    };
    // console.log('ChooseShift', data);
    saveDataToStorage(data, new Date(), 'CHOOSE_SHIFT');
    dispatch({ type: CHOOSE_SHIFT, data });
  };
};

export const GetStoreLocation = (lat, lng) => {
  return async (dispatch, getState) => {
    const StoreData = getState().content.store;
    const RANGE = getState().content.RANGE;
    const SIZE_RADIUS = getState().content.SIZE_RADIUS;
    // const SIZE_RADIUS = useSelector((state) => state.content.SIZE_RADIUS);

    if (lng && lat) {
      //console.log('lat lang', lat, lng);
      let find = StoreData.find((item) => {
        let latUp = parseFloat(item.loc[0]) + RANGE;
        let latDown = parseFloat(item.loc[0]) - RANGE;
        let lngUp = parseFloat(item.loc[1]) + RANGE;
        let lngDown = parseFloat(item.loc[1]) - RANGE;
        let latCheck = false;
        let lngCheck = false;

        if (lat >= latDown && lat <= latUp) latCheck = true;
        if (lng >= lngDown && lng <= lngUp) lngCheck = true;
        if (latCheck && lngCheck) return true;
      });
      // console.log('find', find);

      if (find) {
        return {
          show: true,
          msg: 'BERHASIL',
          status: true,
          data: find,
        };
      } else {
        return { show: false, msg: 'Di Luar Jangkauan', status: false };
      }
    }
  };
};

export const ParseData = (date, shift_alternative = '') => {
  return async (dispatch, getState) => {
    let date_time = new Date(date.date_time);
    // console.log('date', date);
    // console.log('date_time', date_time);
    let SHIFT = getState().content.shift;
    let SHIFT_CHOOSE =
      shift_alternative === ''
        ? getState().content.shiftChoose
        : shift_alternative;

    let newData, status, keterangan, pass;
    // console.log('SHIFT', SHIFT);
    // console.log('SHIFT_CHOOSE', SHIFT_CHOOSE);

    SHIFT.find((item) => {
      // console.log('item', item);
      if (item.id === SHIFT_CHOOSE.shift) {
        // shift undefined
        let hr = date_time.getHours();
        let mnt = date_time.getMinutes();
        let scd = date_time.getSeconds();
        let startWork = item.in_start.split(':');
        let endWork = item.in_off.split(':');
        newData = { ...item, pegawai_absen: `${hr}:${mnt}:${scd}` };

        if (
          (hr >= parseInt(startWork[0]) && hr <= parseInt(endWork[0])) ||
          (mnt < parseInt(endWork[1]) && hr <= parseInt(endWork[0]))
        ) {
          let hourAbsen = item.in_work.split(':');
          if (
            hr < parseInt(hourAbsen[0]) ||
            (hr <= parseInt(hourAbsen[0]) &&
              parseInt(hourAbsen[1]) !== 0 &&
              mnt <= parseInt(hourAbsen[1]))
          ) {
            status = 'ontime';
            pass = true;
            keterangan = 'Absen tepat waktu';
          } else {
            status = 'late';
            pass = true;
            keterangan =
              'Telat : ' + LateCalculation(item.in_work, newData.pegawai_absen);
          }
          newData = item;
          return newData;
          // return item;
        } else if (hr < parseInt(startWork[0])) {
          status = 'disable';
          pass = false;
          keterangan = 'Belum memasuki jam shift ' + item.shift;
        } else {
          status = 'disable';
          pass = false;
          keterangan = 'Diluar jam absen';
        }
      }
    });
    // console.log('newData2', newData);
    let timez = new Intl.DateTimeFormat().resolvedOptions();
    timez = {
      ...timez,
      waktu:
        timez.timeZone === 'Asia/Jakarta'
          ? 'WIB'
          : timez.timeZone === 'Asia/Jayapura'
          ? 'WIT'
          : 'WITA',
    };

    newData = {
      dataShift: newData,
      checkIn: {
        date_time: SHIFT_CHOOSE.absen,
        keterangan,
        status,
      },
      pass,
      timeZone: timez.timeZone,
      indoTimeZ: timez,
    };

    if (shift_alternative === '') {
      let cacheData = { ...SHIFT_CHOOSE, ...newData };
      // console.log('cacheData', cacheData);
      saveDataToStorage(cacheData, new Date(), 'CHOOSE_SHIFT');
      dispatch({ type: CHOOSE_SHIFT, cacheData });
    }
    // console.log('newData', newData);
    return newData;
  };
};

import { LoginApi, SignupApi, ForgotPasswordApi } from '../Context/AuthContext';
import { CheckToken } from '../Context/AuthContext';
import { saveDataToStorage } from '../ApiGate';
import { AuthModel } from '../Model/AuthModel';

export const SIGNUP = 'SIGNUP';
export const SIGNIN = 'SIGNIN';
export const AUTHENTICATE = 'AUTHENTICATE';
export const LOGOUT = 'LOGOUT';

// const auth = getAuth();

export const Authenticate = (data) => {
  return async (dispatch) => {
    // await dispatch(GetHistory());
    dispatch({ type: AUTHENTICATE, data: { ...data } });
  };
};

export const CheckAuthenticate = () => {
  return async (dispatch) => {
    let res = await CheckToken();
    // console.log('CheckAuthenticate res', res);
  };
};

export const signupAuthenticate = (params) => {
  return async (dispatch, getState) => {
    let res = await SignupApi(params);
    // console.log('SignupApi', res);
    if (res.status) {
      res = res.data;
      let data = new AuthModel(
        res.id,
        res.username,
        res.token,
        res.phone,
        res.active,
        res.Position ? res.Position.name : null
      );
      // console.log('data', data);
      dispatch(Authenticate(data));
      saveDataToStorage(data, new Date(), 'UID');
      return { success: true, data };
    }
    if (res.errors) alert(res.errors);
    return { status: false, error: res.errors };
  };
};

export const signinAuthenticate = (user, password) => {
  return async (dispatch, getState) => {
    let res = await LoginApi(user, password);
    //console.log('LoginApi', res);

    if (res.status) {
      res = res.data;
      let data = new AuthModel(
        res.id,
        res.username,
        res.token,
        res.phone,
        res.active,
        res.Position ? res.Position.name : null
      );

      dispatch(Authenticate(data));
      saveDataToStorage(data, new Date(), 'UID');

      return { success: true, data };
    }
    if (res.errors) return { status: false, error: res.errors };
  };
};

export const forgotPassword = (data) => {
  return async () => {
    let res = await ForgotPasswordApi(data);
    // console.log('ForgotPasswordApi', res);
    if (res.status) {
      // dispatch(Authenticate(res.uid, res.accessToken, res.expire));
      return res;
    } else {
      if (res.errors) alert(res.errors);
      return { status: false, error: res.errors };
    }
  };
};

export const logoutAct = () => {
  return (dispatch) => {
    localStorage.removeItem('UID');
    localStorage.removeItem('HISTORY');
    localStorage.removeItem('ABSEN');
    dispatch({ type: LOGOUT });
    return true;
  };
};

// ===============================
// '{"checkIn":{"status":true,"date_time":"2022-03-29 09:41:37","store":"34","type":"in","dataShift":{"id":1,"shift":"Pagi","in_start":"08:00:00","in_work":"09:30:00","in_off":"11:00:00","out_start":"17:00:00","out_work":"17:30:00","out_off":"19:00:00"},"request":{"latitude":"-6.5544207","longtitude":"106.8532496","time":"2022-03-29 09:41:37","shift_id":"1","store_id":"34"}},"checkOut":{"status":false,"startIn":"17:00:00"}}';

import { LOGOUT, AUTHENTICATE } from '../actions/AuthAction';
import { CheckToken } from '../Context/AuthContext';
import { CheckCache } from '../ApiGate';

const initialState = {
  token: CheckCache('UID', true),
  userId: CheckCache('UID', true, 'userId'),
  userName: CheckCache('UID', true, 'username'),
  position: CheckCache('UID', true, 'position'),
  phone: CheckCache('UID', true, 'phone'),
  active: CheckCache('UID', true, 'active'),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATE:
      // console.log('=== AUTHENTICATE ===', action);
      return {
        token: action.data.token,
        userId: action.data.userId,
        userName: action.data.username,
        position: action.data.position,
        phone: action.data.phone,
        active: action.data.active,
      };

    case LOGOUT:
      return initialState;

    default:
      return state;
  }
};

// function name(params) {

// }
